import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
	  path:'/detailspage',
	  name:'detailspage',
	  component:()=>import('../views/detailspage.vue')
  },
  {
	  path:'/authentication',
	  name:'authentication',
	  component:()=>import('../views/authentication.vue')
	  
  },
  {
	  path:'/listPage',
	  name:'listPage',
	  component:()=>import('../views/listPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
